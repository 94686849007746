import React,{ Component } from 'react';
import { Card, CardBody, Col, Container, Row,Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Show';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'; 
import Datatable from 'react-bs-datatable';
import { MDBDataTable } from 'mdbreact';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider , { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
const { SearchBar } = Search;
const tableColumns = [ 
  {
    dataField: "id",
    text: "#",
    sort: true,
    headerStyle: { width:"10%" }
  },  
  {
      dataField: "first_name",
      text: "Name",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "mobile_no",
      text: "Mobile",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "user_lat",
      text: "Lattitude",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "user_long",
      text: "Logitude",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "timestamp",
      text: "Date/Time",
      sort: true,
      headerStyle: { width:"10%" }
    },
    {
      dataField: "action",
      text: "Panic Evidences",
      headerStyle: { width:"10%" },
      csvExport:false
    },
  ];

const image = `${process.env.PUBLIC_URL}/img/map-location.png`;
class Evidence_list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      formData:[],
    }
    this.flag = 0;
    this.header = [
      { title: 'Sr. No.', prop: 'idd', filterable: true },
      { title: 'Employee Name', prop: 'first_name', filterable: true },
      { title: 'Employee SAP Code', prop: 'sap_code' },
      { title: 'Employee Mobile No.', prop: 'mobile_no' },
      { title: 'Employee Email', prop: 'email' },
      { title: 'Latitude', prop: 'user_lat'},
      { title: 'Longitude', prop: 'user_long'},
      { title: 'Date', prop: 'date'},
      { title: 'Time', prop: 'time'},
      { title: 'Panic Evidence', prop: 'action',cell: (row)=><div><center><i id={row.id} className="fa fa-eye ptr_css" onClick={() =>this.view(row.id)}></i>&nbsp;&nbsp;&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + row.user_lat +","+row.user_long}><img id={row.id} src={image} style={{width: '20px',height: '16px'}} /></a></center></div> },
      ];

    this.customLabels = {
      first: '<<',
      last: '>>',
      prev: '<',
      next: '>',
      show: 'Display',
      entries: 'rows',
      noResults: 'There is no data to be displayed',
    };
    
  }

  view(e) { 
    this.props.history.push('/forms/evidence_view/'+e);
  }

  edit(e) {    
   // alert(e);
    this.props.history.push('/forms/add/'+e);
   }

  onSortFunction = {
    date(columnValue) {
      // Convert the string date format to UTC timestamp
      // So the table could sort it by number instead of by string
      return moment(columnValue, 'Do MMMM YYYY').valueOf();
    },
  };

downloadpdf(e) {
    toast('PDF file is generating.', {containerId: 'B',type: toast.TYPE.SUCCESS})
    const data = new FormData() 
    data.append('e_id', e);
    fetch(myConstClass.BASE_URL+`/user/downloadpdf`, {
        method: 'POST',
        body: data,
    }).then(response =>  response.json()).then((response) => {
        if(response){
        //  toast('Pdf is generating', {containerId: 'B',type: toast.TYPE.SUCCESS})
            window.open(response.pdf_url, "_blank");
        } else{
            //toast('Pdf generating Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
        }
    }).catch();
}

downloadzip(e) {
    toast('Zip file is generating.', {containerId: 'B',type: toast.TYPE.SUCCESS})
    const data = new FormData() 
    data.append('e_id', e);
    fetch(myConstClass.BASE_URL+`/user/downloadzip`, {
    method: 'POST',
    body: data,
    }).then(response =>  response.json()).then((response) => {
        if(response){
        //  toast('Pdf is generating', {containerId: 'B',type: toast.TYPE.SUCCESS})
            window.open( response.zip_url, 'Download');
        }else{
            //toast('Zip file generating Failed..!', {containerId: 'B',type: toast.TYPE.ERROR})
        }
    }).catch();
}

  componentDidMount() {
    fetch(myConstClass.BASE_URL+'/user/getUserEvidence')
    .then(response => response.json())
    .then(response =>{
      let i=1;
      response.forEach(element => {
        var id = element.id;
      //  element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() =>this.view(id)}></i>&nbsp;&nbsp;&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + element.user_lat +","+element.user_long}><img id={id} src={image} style={{width: '20px',height: '16px'}} /></a></center></div>;
        element.action = <div><center><i id={id} className="fa fa-eye ptr_css" onClick={() =>this.view(id)}></i>&nbsp;&nbsp;<a target="_blank" href={"https://www.google.com/maps/search/?api=1&query=" + element.user_lat +","+element.user_long}><img id={id} src={image} style={{width: '20px',height: '16px'}} /></a>&nbsp;&nbsp;<i id={id} className="fa fa-file-pdf-o ptr_css" onClick={() =>this.downloadpdf(id)}></i>&nbsp;
          <i id={id} className="fa fa-download ptr_css" onClick={() => this.downloadzip(id)}></i>
          &nbsp;&nbsp;
        </center></div>;
        element.id = i;
        i++;
      });
      // this.setState({posts: response})
      this.setState({formData: response})
    })
    .catch(err => console.log(err))

    if(!localStorage.getItem('session_id')){
      console.log('History:',this.props);      
      this.props.history.push('/log_in');
  }
        
  }

render() {
  
  return (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">All Evidences
       </h3>
      </Col>
      <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
      <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
         
    </Row>
    <Row>
    <Col md={12} lg={12}>
    <Card>
    <ToolkitProvider
              keyField="name"
              data={this.state.formData}
              columns={tableColumns}
              exportCSV
              search
            >
              {props => (
                    <div>
                        
                          <CardBody>
                          <SearchBar { ...props.searchProps } />
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4
                              bordered={false}
                              pagination={paginationFactory({
                                // sizePerPage: 25,
                                sizePerPage: 10,
                                sizePerPageList: [5, 10, 25, 50]
                              })}
                            />
                          </CardBody>
                    </div>
                  )}
                </ToolkitProvider>
      {/* <CardBody> */}
      
    {/* <Datatable
  tableHeader={this.header}
  tableBody={this.state.posts}
  keyName="userTable"
  tableClass="striped hover responsive"
  rowsPerPage={10}
  rowsPerPageOption={[5, 10, 15, 20]}
  initialSort={{prop: "`title`", isAscending: true}}
  onSort={this.onSortFunction}
  labels={this.customLabels}
  
/> */}
    {/* <MDBDataTable
          striped
          hover
          data={this.state.posts}
        /> */}
    
      {/* </CardBody> */}
      </Card>
      </Col>
    </Row>
  </Container>)
}
}

export default withTranslation('common')(Evidence_list);
