import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as myConstClass from '../../../constant.js';
import 'font-awesome/css/font-awesome.min.css';
import "video-react/dist/video-react.css";

class Bulletin_news_view extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bulletin_news: [],
    }
  }

  componentDidMount() {
    const answer_array = this.props.location.pathname.split('/');
    if (answer_array['3']) {
      fetch(myConstClass.BASE_URL + '/bulletin_news/getBulletinNewsView/' + answer_array['3'])
        .then(response => response.json())
        .then(response =>
          this.setState({ bulletin_news: response })
        )
        .catch(err => console.log(err))
    }

    if (!localStorage.getItem('session_id')) {
      this.props.history.push('/log_in');
    }
  }

  render() {
    const { bulletin_news } = this.state;
    return (
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Bulletin News Detail</h3>
          </Col>
          <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.BOTTOM_LEFT} />
          <ToastContainer enableMultiContainer containerId={'B'} position={toast.POSITION.TOP_RIGHT} />
        </Row>
        <Row>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Title : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{bulletin_news.title}</h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Description : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5><div dangerouslySetInnerHTML={{ __html: bulletin_news.description }}></div></h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>News Date : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{bulletin_news.news_date}</h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Source of news : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{bulletin_news.news_source}</h5>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Image : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <img src={bulletin_news.image_path} alt="" style={{ width: 'auto', maxWidth: '350px' }} />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={2} lg={2}>
                    <h5><b>Date/Time : </b></h5>
                  </Col>
                  <Col md={6} lg={6}>
                    <h5>{bulletin_news.created_at}</h5>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}

export default withTranslation('common')(Bulletin_news_view);
